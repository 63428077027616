import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Img from "gatsby-image";

import SEO from "../components/MetaSeo";
import Layout from "../components/layout";
import { useMount } from "react-use";

const Destinos = ({ data }) => {
  const [first, ...destinos] = get(data, "allFile.nodes", []);
  const title = "Destinos";
  const slug = "destinos";

  const Card = ({ name, childImageSharp }) => {
    const nameFixed = name
      .replace("destinos-", "")
      .replace("_", " ")
      .replace(/-/g, " ");
    const link = name.replace("destinos-", "").replace("_", "-");

    return (
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div className="rounded-lg h-64 overflow-hidden">
          {childImageSharp && (
            <a href={`https://dreamsintercambios.com.br/${link}/`}>
              <Img
                className="object-cover object-center h-full w-full"
                alt={name}
                fluid={childImageSharp.fluid}
              />
            </a>
          )}
        </div>

        <a
          href={`https://dreamsintercambios.com.br/${link}/`}
          className="text-indigo-500 inline-flex items-center mt-3"
        >
          <h2 className="text-xl font-medium title-font text-gray-900 mt-5 capitalize">
            {nameFixed}
          </h2>
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-2"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    );
  };

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      {/* <MiniHero name={title} /> */}

      <div className="container mx-auto">
        <section className="text-gray-700 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:flex-grow w-full lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Destinos
              </h1>

              <section className="text-gray-700 body-font">
                <div className="container px-0 py-24 mx-0">
                  <div className="flex flex-col">
                    <div className="h-1 bg-gray-200 rounded overflow-hidden">
                      <div className="w-24 h-full bg-indigo-500"></div>
                    </div>
                    <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
                      <div className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">
                        Escolha seu sonho
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                    {destinos && destinos.map(dest => <Card {...dest} />)}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  fragment PageImage on File {
    childImageSharp {
      fluid(quality: 90, maxWidth: 1800) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }

  query {
    allFile(
      filter: { name: { regex: "/destino/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        relativePath
        relativeDirectory
        name
        ...PageImage
      }
    }
  }
`;

export default Destinos;
